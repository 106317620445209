import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import FirebaseUser from '../../models/FirebaseUser';
import Login from '../../models/Login';
import Person from '../../models/Person';
import Role from '../../models/Role';
import { RootState } from '../store';

export interface SessionUser {
  firebaseUser: FirebaseUser;
  currentUser: Login;
  roleUser: Role;
  personData: Person;
}
export interface SessionUserState {
  sessionUser: SessionUser | null;
}

const initialState: SessionUserState = {
  sessionUser: null,
};

export const authenSlice = createSlice({
  name: 'authen',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuthenUser: (state, action: PayloadAction<SessionUser | null>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // if(state.sessionUser == null) {
        state.sessionUser = action.payload;
      // }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

export const { setAuthenUser } = authenSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPagination = (state: RootState) => state.authen;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default authenSlice.reducer;
