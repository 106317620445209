const FirebaseConfig = {
    apiKey: "AIzaSyDrGI8QQKPYP7ql0edD7zmrJdTDGQsVi2o",
    authDomain: "doctorassistantprototype.firebaseapp.com",
    projectId: "doctorassistantprototype",
    storageBucket: "doctorassistantprototype.appspot.com",
    messagingSenderId: "788081815662",
    appId: "1:788081815662:web:237060d8ae56d1dcda367f",
    measurementId: "G-YKS6JBK1EB"
}

export default FirebaseConfig;