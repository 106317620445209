import { initializeApp } from 'firebase/app';
import FirebaseConfig from '../constants/FirebaseConfig';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { SessionUser } from '../store/authen/authenSlice';
import FirebaseUser from '../models/FirebaseUser';
import Role from '../models/Role';
import ApiConfig from '../constants/ApiConfig';
import axios from 'axios';

const app = initializeApp(FirebaseConfig);

const createSessionUser = (firebaseUser: any, responseUser: any, personData: any) => {
  console.log("firebaseUser: ", firebaseUser);
  let currentUser: SessionUser = {
    currentUser: responseUser,
    firebaseUser: createFirebaseUser(firebaseUser),
    roleUser: createRoleUser(responseUser),
    personData: personData
  }
  return currentUser;
}

const createFirebaseUser = (firebaseUser: any) => {
  let fUser: FirebaseUser = {
    uid: firebaseUser.uid,
    email: firebaseUser.email,
    providerId: firebaseUser.providerId,
    accessToken: firebaseUser.accessToken
  }
  return fUser;
}

const createRoleUser = (responseUser: any) => {
  console.log("responseUser: ", responseUser);
  let roleUser: Role = {
    id: responseUser.role_id,
    code: responseUser.role_code,
    role_name: responseUser.role_name,
    description: '',
    role_type: '',
  }
  return roleUser;
}
export const firebaseAuthentication = async (username: string, password: string) => {
    const auth = await getAuth(app);
    let result = {status: false, error: null, user: null};
    await signInWithEmailAndPassword(auth, username, password)
      .then(async(userCredential) => {
        // Signed in 
        const user: any  = userCredential.user;

        console.log("Login Success: ", user);
        result.user = user
        result.status = true;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        result.status = false;
        result.error = error;
        console.log("Firebase error: ", errorCode, errorMessage);
      });
      return result
}

export const authentication = async(user: any) => {
    let currentUser: SessionUser = createSessionUser(user, {}, {});
    return currentUser;
    // let loginUrl = `${ApiConfig.backendUrl}/login`;
    // console.log("authen param: ", {
    //   fuid: user.uid,
    //   token: user.accessToken
    // });
    // let res = await axios.post(loginUrl, {
    //   fuid: user.uid,
    //   token: user.accessToken
    // }, {
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': '*',
    //     'Access-Control-Allow-Credentials': 'true'
    //   }
    // });
    // console.log("After Post ");
    // console.log("response status: ", res.status);
    // if(res.status === 200){
    //   console.log("response data: ", res.data);
    //   let resData = res.data;
    //   let currentUser: SessionUser = createSessionUser(user, resData.data.loginData, resData.data.personData);
    //   return currentUser;
    // }else{
    //   console.log("error code: ", res.status)
    //   return null;
    // }
}

export const signInWithGoogle = async (): Promise<SessionUser | null> => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  let returnResult: SessionUser | null = null;
  await signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;

      console.log("token: ", token);
      console.log("user: ", user);
      returnResult = await authentication(user);
      console.log("returnResult then: ", returnResult)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("error: ", errorMessage);
      // ...
    });
    console.log("returnResult: ", returnResult)
    return returnResult;
}