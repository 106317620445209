import { Button, Input } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { GoogleCircleFilled } from '@ant-design/icons';
import styles from './SignUp.module.css'

const SignUp = () => {

    const googleSignUp = () => {
        
    }

    return (
        <Layout>
            <Header>Header</Header>
            <Content className={styles['login-container']}>
                <Button type="primary" shape="round" icon={<GoogleCircleFilled />} size={'large'} onClick={googleSignUp}>
                    Sign Up with Google
                </Button>
            </Content>
        </Layout>
    );
}

export default SignUp;