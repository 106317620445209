import { Button, Input, Layout, notification, Result } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import styles from './Login.module.css';
import { useAppDispatch } from '../../store';
import { SessionUser, setAuthenUser } from '../../store/authen/authenSlice';
import { useNavigate } from 'react-router-dom';
import * as authenService from '../../services/AuthenService';
import { FrownOutlined, GoogleCircleFilled, SmileOutlined } from '@ant-design/icons';

const Login = () => {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [navigatePage, setNavigatePage] = useState<string>();
    const [fToken, setFToken] = useState<string>();

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    const loginHandler = async() => {
        console.log("username: ", username);
        console.log("password: ", password);
        let result = await authenService.firebaseAuthentication(username, password);
        let user: any = result.user
        setFToken(user ? user.accessToken : '')
        // setTimeout(async() => {
        //     let currentUser = await authenService.authentication(user);
        //     if(!currentUser){
        //         openNotification("Login Fail", "เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", false);
        //         return;
        //     }
        //     dispatch(setAuthenUser(currentUser));
        //     openNotification("Login Success", `ยินดีต้อนรับ ${currentUser.personData.firstname} ${currentUser.personData.lastname}`, true);
        //     setNavigatePage("/app");
        // }, 500);
    }
    const signUpHandler = async() => {
        console.log("username: ", username);
        console.log("password: ", password);
        let user = authenService.firebaseAuthentication(username, password);
        setTimeout(async() => {
            let currentUser = await authenService.authentication(user);
            dispatch(setAuthenUser(currentUser));
            setNavigatePage("/app");
        }, 500);
    }

    const googleSignUp = async () => {
        let currentUser: SessionUser | null = await authenService.signInWithGoogle();
        if(currentUser){
            openNotification("Login Success", `ยินดีต้อนรับ ${currentUser.personData.firstname} ${currentUser.personData.lastname}`, true);
            dispatch(setAuthenUser(currentUser));
            // setNavigatePage("/app");
            setFToken(currentUser.firebaseUser.accessToken);
            return;
        }
        openNotification("Login Fail", "เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", false);
        // return;
    }

    
    const openNotification = (title: string, description: string, status: boolean) => {
        let icon = status ? <SmileOutlined style={{ color: '#108ee9' }} /> : <FrownOutlined style={{ color: '#F02707' }} />;
        notification.open({
            message: title,
            description: description,
            icon: icon,
        });
    };

    return (
        <Layout>
            <Header>Header</Header>
            <Content className={styles['login-container']}>
                <div className={styles['component-box']}>
                    <div className={styles['title-bar']}></div>
                    <Input placeholder="Username" type={"text"} value={username} onChange={e => setUsername(e.target.value)}/>
                    <Input placeholder="Passowrd" type={"password"} value={password} onChange={e => setPassword(e.target.value)} />
                    <div>
                        <Button type={"primary"} onClick={loginHandler}>Login</Button>
                        <Button type={"default"} style={{marginLeft: 5}} icon={<GoogleCircleFilled />} onClick={googleSignUp}>
                            Sign In with Google
                        </Button>
                        <Button type={"primary"} onClick={signUpHandler}>Sign up</Button>
                    </div>
                    <Input.TextArea value={fToken} style={{height: 350, margin: 10, display: fToken && fToken.trim() != "" ? "flex" : "none"}}></Input.TextArea>
                </div>
            </Content>
        </Layout>
    );
}

export default Login;