import React, { Children } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, useRoutes, RouteObject } from 'react-router-dom';

import SignUp from './views/signup/SignUp';
import Login from './views/login/Login';
import { Counter } from './views/counter/Counter';
import XtremeLayout from './views/backend/layout/XtremeLayout';
import Dashboard from './views/backend/dashboard/Dashboard';
import UploadImages from './views/backend/uploadImage/UploadImages';
import SimpleMap from './views/backend/map/SimpleMap';
import TransactionList from './views/backend/uploadImage/TransactionList';
import ProcessedMap from './views/backend/uploadImage/ProcessedMap';
function App() {
  const loginRoute: RouteObject = {
    path: '',
    element: <Login />
  }

  const signUpRoute: RouteObject = {
    path: 'signup',
    element: <SignUp />
  }
  
  const authened: RouteObject = {
    path: 'app',
    element: <XtremeLayout />,
    children: [
      {path: '', element: <Dashboard />},
      {path: 'dashboard', element: <Dashboard />},
      {path: 'upload', element: <UploadImages />},
      {path: 'upload/:id', element: <UploadImages />},
      {path: 'transactionList', element: <TransactionList />},
      {path: 'map/:id', element: <ProcessedMap />},
      
    ]
  }
  
  // const routing = useRoutes([loginRoute, signUpRoute, authened]);
  const routing = useRoutes([loginRoute, signUpRoute]);
  return routing;
}

export default App;
